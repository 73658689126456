import { ICoreContext } from '@msdyn365-commerce/core';
import { ProductSearchResultExt } from "../../modules/dlvry-search-result-container/components";
import { Customer } from '@msdyn365-commerce/retail-proxy';
import { hasAccessToBuy } from '.';

export interface OnBuyHasAccessValidatorResult {
    isValid: boolean;
    message?: string;
}

export async function onBuyHasAccessValidator(
    context: ICoreContext,
    customer: Customer | undefined,
    product: ProductSearchResultExt,
    validationMessage: string | undefined
): Promise<OnBuyHasAccessValidatorResult> {
    if (!product || !product.AttributeValues) {
        return { isValid: true };
    }

    const hasAccess = hasAccessToBuy(context, customer, product.AttributeValues);

    return {
        isValid: hasAccess,
        message: hasAccess ? undefined : validationMessage
    };
}
