import * as React from 'react';
import { ProductPrice, SimpleProduct, ProjectionDomain } from '@msdyn365-commerce/retail-proxy';
import { ICoreContext, getCatalogId } from '@msdyn365-commerce/core';
import { getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

async function getPricesAsync(context: ICoreContext, products: number[], batchSize: number = 30): Promise<ProductPrice[]> {
    const result: ProductPrice[] = [];
    const catalogId = context.request.apiSettings.catalogId ?? getCatalogId(context.actionContext.requestContext);
    const projectDomain: ProjectionDomain = { ChannelId: +context.request.apiSettings.channelId, CatalogId: catalogId };

    // Work with X products at a time
    const batchCount = Math.ceil(products.length / batchSize);

    for (let i = 0; i < batchCount; i++) {
        const batch = products.slice(i * batchSize, (i + 1) * batchSize);
        const prices = await getActivePricesAsync({ callerContext: context.actionContext }, projectDomain, batch, new Date(), null, undefined, true);
        result.push(...prices);
    }

    return result;
}

export function useActivePrices(context: ICoreContext, products: SimpleProduct[] | number[] | undefined, batchSize: number = 30): [ProductPrice[], boolean, Error | undefined] {
    const [activePrices, setActivePrices] = React.useState<ProductPrice[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<Error | undefined>(undefined);

    React.useEffect(() => {
        if (!products || products.length < 1 || !context.request.user.isAuthenticated) {
            setLoading(false);
            return;
        }

        let recIds: number[];

        if (typeof products[0] === 'number') {
            recIds = products as number[];
        } else {
            recIds = (products as SimpleProduct[]).map(product => product.RecordId);
        }

        if (recIds.every(id => activePrices.some(price => price.ProductId === id))) {
            // We already have all the prices
            setLoading(false);
            return;
        }

        getPricesAsync(context, recIds, batchSize)
            .then(prices => setActivePrices(prices))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, [products]);

    return [activePrices, loading, error];
}
