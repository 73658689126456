import { ICoreContext } from "@msdyn365-commerce/core";
import { AttributeValue, Customer } from "@msdyn365-commerce/retail-proxy";
import { getLocale } from "./attributes/locale";
import { getAttributeBooleanOrDefault } from "./attributes";

export function hasAgreementProducts(context: ICoreContext, customer: Customer | undefined): boolean {
    const agreementProductAttributeRecordIds: Number[] | undefined = context.request.app.config?.agreementProductAttributeRecordId?.split(',').map(Number).filter(Boolean);
    // Check if customer has any product agreement that should be shown
    const shouldShowAgreementProducts = agreementProductAttributeRecordIds && agreementProductAttributeRecordIds.length > 0
            ? agreementProductAttributeRecordIds.some(x => customer?.Attributes?.find(y => y.RecordId === x)?.AttributeValue?.BooleanValue)
            : false;
    return shouldShowAgreementProducts;
}

export function getAgreementProductRefinersToHide(context: ICoreContext, customer: Customer | undefined): number[] | undefined {
    const refinerIds = context.request.app.config?.agreementProductAttributeRecordId
        ?.split(',')
        .map(Number)
        .filter(Boolean) as number[] | undefined;

    // Hide all refiners if customer is not available
    if (!customer) {
        return refinerIds;
    }

    const refinersToHide = refinerIds?.filter(refinerId => {
        const attribute = customer.Attributes?.find(x => x.RecordId === refinerId);
        return !attribute?.AttributeValue?.BooleanValue;
    });

    return refinersToHide;
}

let cachedAttributesMap: ReturnType<typeof getLocale> | undefined;

export function isAgreementProduct(contextOrLocale: ICoreContext | string, attributes: AttributeValue[] | undefined): boolean {
    if (!cachedAttributesMap) {
        cachedAttributesMap = getLocale(contextOrLocale);
    }

    const helseNord = getAttributeBooleanOrDefault(attributes, cachedAttributesMap.helseNord, false);
    const municipalityNorth = getAttributeBooleanOrDefault(attributes, cachedAttributesMap.municipalityNorth, false);

    return helseNord || municipalityNorth;
}
