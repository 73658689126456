import { ProductPrice } from "@msdyn365-commerce/retail-proxy";

export function getDiscountBadge(productPrice: ProductPrice | undefined): string | undefined {
    if (!productPrice || !productPrice.DiscountLines || productPrice.DiscountLines.length === 0) {
        return;
    }

    const discountLineWithBadge = productPrice.DiscountLines.find(x => {
        const matches = x.OfferName?.toLowerCase().match(/\{(.*?)\}/i) || '';
        const parts = matches.length > 1 ? matches[1].split(';').map(x => x.trim()) : undefined;
        return parts?.find(x => x.startsWith('badge:'));
    });
    const offerName = discountLineWithBadge?.OfferName;

    if (!offerName) {
        return undefined;
    }

    const badge = /\{.*Badge\s*:\s*(.*?)[};]/i.exec(offerName);

    if (badge && badge.length > 1) {
        return badge[1].trim();
    }

    return undefined;
}