import { ICoreContext } from '@msdyn365-commerce/core';
import nbNO from './nb-NO.json';
import enUS from './en-US.json';

export function getLocale(langOrContext: string | ICoreContext): typeof nbNO {
    const lang = typeof langOrContext === 'string' ? langOrContext : langOrContext.request.locale;

    switch (lang) {
        case 'nb-NO':
            return nbNO;
        case 'en-US':
            return {...nbNO, ...enUS};
        default:
            return nbNO;
    }
}
